import * as React from 'react';
import { Page } from '../components/page';
import { RichText } from '../components/richt_text';
import DownloadIcon from '../icons/download.svg';

import './datenschutz.scss';

const PrivacyPage: React.FunctionComponent = () => {
  return (
    <Page title="Datenschutz">
      <RichText className="privacy_page">
        <h1>
          Datenschutzinformation <br />
          <span className="title_addendum">gemäß Art. 13 ff Datenschutz-Grundverordnung</span>
        </h1>
        <div className="download_section">
          <a
            className="download_link"
            href="/Datenschutzinformation_Homepage _2021.pdf"
            target="_blank"
            rel="norefferer"
          >
            PDF Download
            <DownloadIcon />
          </a>
        </div>
        <p>
          <b className="section_header">1. Präambel:</b>
          Der Schutz Ihrer personenbezogenen und/oder sensiblen Daten wird sehr ernst genommen und
          werden die uns anvertrauten Daten vertraulich und verantwortungsvoll strikt nach den
          europäischen und nationalen Datenschutzbestimmungen sowie dem Telekommunikationsgesetz
          2003 (TKG 2003) verarbeitet. Die nachfolgende Erklärung beschreibt die Art, den Umfang und
          den Zweck der Datenerhebung, für welche Dauer die Daten gespeichert werden und wie Sie
          jederzeit über den Umgang mit Ihren persönlichen Daten bestimmen können.
        </p>
        <p>
          <b className="section_header">2. Verantwortlicher für die Datenverarbeitung:</b>
          Verantwortlicher für die Datenverarbeitung ist
          <div className="centered">
            <b>Dr. Mario Sollhart</b>
            <br />
            Rechtsanwalt | Eingetragener Treuhänder <br />
            Annenstraße 25/III/9-10.
          </div>
          Sie erreichen uns unter der Telefonnummer <a href="tel:0043316832458">+43 316 832458</a>,
          per Fax unter <a href="tel:00433168324584">+43 316 832458-4</a> oder per E-Mail unter{' '}
          <a href="mailto:recht@sollhart.at">recht@sollhart.at</a>.
        </p>
        <p>
          <b className="section_header">3. Personenbezogene Daten:</b>
          Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche
          Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
          Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
          oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
          physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
          Identität dieser natürlichen Person sind, identifiziert werden kann.
        </p>
        <p>
          Besondere Kategorien personenbezogener Daten („sensible Daten“) sind jene Daten, aus denen
          die rassische und ethnische Herkunft, politische Meinungen, religiöse oder
          weltanschauliche Überzeugungen oder die Gewerkschaftszugehörigkeit hervorgehen sowie
          genetische Daten, biometrische Daten zur eindeutigen Identifizierung einer natürlichen
          Person, Gesundheitsdaten oder Daten zum Sexualleben oder der sexuellen Orientierung einer
          natürlichen Person.
        </p>
        <p>
          <b className="section_header">4. Datenverarbeitung:</b>
          Die Nutzung dieser Website einschließlich des Zugriffs auf die darauf befindlichen
          öffentlich zugänglichen Inhalte ist grundsätzlich ohne Angabe Ihrer personenbezogenen
          Daten möglich. Erhoben werden lediglich die von Ihrem Internet-Provider mitgeteilten
          Angaben, darunter insbesondere die Ihnen zugewiesene IP-Adresse sowie die Dauer und die
          Zeit Ihres Besuches. Diese Informationen werden von uns für die Dauer des Website-Besuchs
          gespeichert. Eine Auswertung erfolgt dabei ausschließlich zu statistischen Zwecken unter
          Wahrung der Anonymität der einzelnen Nutzer.
        </p>
        <p>
          Wenn Sie per E-Mail-Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks
          Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
          gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        </p>
        <p>
          Darüber hinaus werden Ihre personenbezogenen Daten nur mit Ihrer freiwilligen Einwilligung
          (<i>Art. 6 Abs. 1 lit. a bzw. Art 9 Abs. 2 lit. a DSGVO</i>) erhoben, verarbeitet und
          genutzt, soweit diese zur Erfüllung der Beauftragung und/oder zur Durchführung und
          Abwicklung unserer Leistungen (<i>Art. 6 Abs. 1 lit. b bzw. Art 9 Abs. 2 lit. f DSGVO</i>)
          erforderlich ist oder wenn eine sonstige rechtliche Grundlage (
          <i>insbesondere ein Vertrag oder eine gesetzliche Bestimmung</i>) im Einklang mit der
          DSGVO vorliegt. Der Umfang der Datenerhebung und Datenverarbeitung ist dabei abhängig von
          der jeweiligen Phase der Geschäftsbeziehung sowie des jeweils angefragten bzw.
          beauftragten Leistungs-/Dienstleistungsumfanges; Insbesondere werden folgende
          personenbezogene Daten verarbeitet: Titel, Name, Anschrift und Kontaktdaten (
          <i>Telefonnummer; Faxnummer, Emailadresse</i>), Geburtsdatum, Alter, Geschlecht,
          Sozialversicherungsnummer, Firmenbuchnummer, Geschäftliche Korrespondenz (
          <i>E-Mails, Gesprächsprotokolle, Faxe, Briefe</i>) Verrechnungs-, Überweisungs- und
          Buchhaltungsdaten, Bankdaten (<i>für die Abwicklung von Bankeinzügen</i>),
          Videoaufzeichnungen, Fotos, Stimmaufnahmen von Personen, aber auch „sensible Daten“, wie
          rassische und ethnische Herkunft, wie politische Meinungen, wie religiöse oder
          weltanschauliche Überzeugungen oder die Gewerkschaftszugehörigkeit, wie biometrische
          Daten, wie Gesundheitsdaten, wie Daten zum Sexualleben oder der sexuellen Orientierung
          einer natürlichen Person oder Daten im Zusammenhang mit einem Strafverfahren.
        </p>
        <p>
          Sollten Sie uns Ihre personenbezogenen Daten nicht oder nicht vollständig zur Verfügung
          stellen, so sind wir möglicherweise nicht in der Lage, die Aufgaben und Pflichten Ihnen
          gegenüber vollständig zu erfüllen.
        </p>
        <p>
          Weiters informieren wir Sie darüber, dass im Rahmen unserer Tätigkeiten und Betreuung
          regelmäßig auch personenbezogene Informationen von Ihnen von dritten Stellen bezogen
          werden.
        </p>
        <p>
          <b className="section_header">5. Datensicherheit, Datenaufbewahrung:</b>
          Der Schutz Ihrer personenbezogenen Daten erfolgt durch entsprechende organisatorische und
          technische Vorkehrungen. Diese Vorkehrungen betreffen insbesondere den Schutz vor
          unerlaubtem, rechtswidrigem oder auch zufälligem Zugriff, Verarbeitung, Verlust,
          Verwendung und Manipulation. Unsere Mitarbeiter sind im Umgang von personenbezogenen Daten
          geschult.
        </p>
        <p>
          Ungeachtet der Bemühungen der Einhaltung eines stets angemessen hohen Standes der
          Sorgfaltsanforderungen kann nicht ausgeschlossen werden, dass Informationen, die Sie uns
          über das Internet bekannt geben, von anderen Personen eingesehen und genutzt werden. Bitte
          beachten Sie, dass wir daher keine wie immer geartete Haftung für die Offenlegung von
          Informationen aufgrund nicht von uns verursachter Fehler bei der Datenübertragung und/oder
          unautorisiertem Zugriff durch Dritte übernehmen. (
          <i>z.B. Hackerangriff auf E-Mail-Account bzw. Telefon, Abfangen von Faxen, etc.</i>).
        </p>
        <p>
          Wir sind bemüht sicherzustellen, dass Datenpannen frühzeitig erkannt und gegebenenfalls
          unverzüglich Ihnen bzw. der zuständigen Aufsichtsbehörde unter Einbezug der jeweiligen
          Datenkategorien, die betroffen sind, gemeldet werden.
        </p>
        <p>
          Wir werden Ihre personenbezogenen Daten nicht länger aufbewahren als dies zur Erfüllung
          der vertraglichen bzw. gesetzlichen Verpflichtungen und/oder zur Abwehr allfälliger
          Haftungsansprüche sowie als Beweis für den Fall rechtlicher Auseinandersetzungen (
          <i>bis zu 30 Jahren gemäß dem Allgemeinen Bürgerlichen Gesetzbuch</i>) erforderlich ist.
        </p>
        <p>
          <b className="section_header">6. Übermittlung an Dritte:</b>
          Zur Erfüllung der Beauftragung und/oder Bestellung sowie zur Durchführung und Abwicklung
          unserer Leistungen ist es möglicherweise auch erforderlich, Ihre Daten an Dritte (
          <i>
            z.B. Gerichte, Behörden, Substitute, Gegenseite, Versicherungen, Dienstleister, derer
            wir uns bedienen und denen wir Daten zur Verfügung stellen, etc.
          </i>
          ) weiterzuleiten. Eine Weiterleitung Ihrer Daten erfolgt ausschließlich auf Grundlage der
          DSGVO, insbesondere zur Erfüllung der Beauftragung und/oder zur Durchführung und
          Abwicklung unserer Leistungen oder aufgrund Ihrer vorherigen Einwilligung.
        </p>
        <p>
          Manche der oben genannten Empfänger Ihrer personenbezogenen Daten befinden sich außerhalb
          Ihres Landes oder verarbeiten dort Ihre personenbezogenen Daten. Das Datenschutzniveau in
          anderen Ländern entspricht unter Umständen nicht dem Österreichs. Wir übernehmen Ihre
          personenbezogenen Daten jedoch nur in Länder, für welche die EU-Kommission entschieden
          hat, dass sie über ein angemessenes Datenschutzniveau verfügen oder es werden Maßnahmen
          gesetzt, um zu gewährleisten, dass alle Empfänger ein angemessenes Datenschutzniveau
          haben.
        </p>
        <p>
          Auch wenn wir uns eines Auftragsverarbeiters bedienen, bleiben wir für den Schutz Ihrer
          Daten verantwortlich bzw. verpflichten wir die Auftragsverarbeiter vertraglich die Daten
          nur zum jeweils vereinbarten Zweck zu verarbeiten und anschließend zu löschen.
        </p>
        <p>
          <b className="section_header">7. Betroffenenrechte:</b>
          <p>
            Sie haben gemäß Art 15 DSGVO das Recht, jederzeit Auskunft über Ihre gespeicherten
            personenbezogenen Daten zu erhalten. Ebenso haben Sie gemäß Art. 16 DSGVO das Recht auf
            Berichtigung falscher oder unvollständiger personenbezogener Daten.
          </p>
          <p>
            Gemäß Art. 17 DSGVO haben Sie – abgesehen von einer vorgeschriebenen Datenverarbeitung
            zur Leistungs-/Dienstleistungsabwicklung - das Recht auf Löschung Ihrer
            personenbezogenen Daten. Weiters haben Sie gemäß Art. 18 DSGVO das Recht auf
            Einschränkung der Verarbeitung sämtlicher erhobener personenbezogener Daten.
          </p>
          <p>
            Gemäß Art. 20 DSGVO steht Ihnen das Recht auf Datenübertragung offen, das ist das Recht,
            ungehinderte und uneingeschränkte Übermittlung erhobener personenbezogener Daten an
            einen Dritten zu verlangen.
          </p>
          <p>
            Ebenso haben Sie gemäß Art 21 DSGVO das Recht, aus Gründen, die sich aus Ihrer
            besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die zur Wahrnehmung einer Aufgabe erforderlich ist, die im
            öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns
            übertragen wurde oder die zur Wahrung unserer berechtigten Interessen oder eines Dritten
            erforderlich ist, Widerspruch einzulegen.
          </p>
          <p>
            Änderungen oder einen Widerruf der Einwilligung zur Datenverarbeitung können Sie gemäß
            Art. 21 DSGVO jederzeit mit Wirkung für die Zukunft vornehmen.
          </p>
          <p>
            Die genannten Rechte stehen Ihnen grundsätzlich kostenfrei zur Verfügung. Sollten Sie
            jedoch eines der genannten Rechte offenkundig unbegründet oder besonders häufig
            wahrnehmen, können wir bei der weiteren Bearbeitung ein angemessenes Entgelt verlangen
            oder aber die Bearbeitung ihrer Anfragen verweigern.
          </p>
          <p>
            Sollten Sie der Meinung sein, dass wir bei der Verarbeitung Ihrer personenbezogenen
            Daten europäische oder österreichische Datenschutzbestimmungen verletzen, haben Sie die
            Möglichkeit sich an die österreichische Datenschutzbehörde zu wenden. Gerne stehen wir
            Ihnen vorab zur Aufklärung von allfälligen Fragen hinsichtlich der Verarbeitung der
            personenbezogenen Daten zur Verfügung.
          </p>
          <p>
            Wir sind für Sie unter den angeführten Kontaktdaten jederzeit für Ihre Fragen oder Ihren
            Widerruf erreichbar.
          </p>
          <p>
            <b className="section_header">8. Cookies, Google Maps:</b>
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine
            Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten
            keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten.
            Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie
            ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
          </p>
          <p>
            Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie
            über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der
            Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
          </p>
          <p>
            Auf unserer Webseite verwenden wird Google Maps für die Darstellung von
            Karteninformationen. Bei der Nutzung von Google Maps werden von Google auch Daten über
            die Nutzung der Maps-Funktionen durch Besucher unserer Webseiten verarbeitet und
            genutzt. Ausführliche Informationen über die Datenverarbeitung durch Google bitten wir
            Sie den Hinweisen zum Datenschutz von Google auf{' '}
            <a
              href="https://www.google.at/intl/de/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.google.at/intl/de/policies/privacy/
            </a>{' '}
            zu entnehmen. Im dort befindlichen Datenschutz-Center können Sie auch Ihre Einstellungen
            verändern, sodass Sie Ihre Daten verwalten und schützen können.
          </p>
          <p>
            <b className="section_header">9. Disclaimer:</b>
            Die auf dieser Website bereitgestellten Informationen dienen allgemeinen
            Informationszwecken. Weder die hier zur Verfügung gestellten Informationen noch jegliche
            E-Mail oder anderweitige elektronische Kontaktaufnahme über diese Website begründen ein
            Vertragsverhältnis.
          </p>
          <p>
            Eine Haftung für die Aktualität, Richtigkeit, Vollständigkeit und Gesetzeskonformität
            der zur Verfügung gestellten Informationen wird von uns nicht übernommen.
          </p>
          <p>
            <b>RA Dr. Mario Sollhart</b> als Betreiber dieser Website ist bestrebt, die angebotenen
            Inhalte sorgfältig zu prüfen und nach bestem Wissen und Gewissen aktuell, vollständig
            und richtig darzustellen und aktuell zu halten. Dennoch wird jegliche Haftung
            ausgeschlossen, die sich aus der Nutzung (oder Nichtnutzung) der angebotenen
            Informationen ergeben können.
          </p>
          <p>
            <b>RA Dr. Mario Sollhart</b> übernimmt keine Verantwortung für die Inhalte von externen
            Websites Dritter, die über Hyperlinks von dieser Website erreicht werden können oder die
            ihrerseits auf diese Website verweisen. <b>RA Dr. Mario Sollhart</b> übernimmt weder die
            Verantwortung für den Inhalt solcher verlinkten Websites noch für deren Aktualität,
            Richtigkeit, Vollständigkeit oder Gesetzeskonformität. Links zu externen Websites
            Dritter werden als zusätzliches Service zur Verfügung gestellt. Diese Websites liegen
            außerhalb der Kontrolle von <b>RA Dr. Mario Sollhart</b>.
          </p>
          <p>
            <b>RA Dr. Mario Sollhart</b> übernimmt keine Haftung für jegliche unmittelbaren oder
            mittelbaren Schäden, Schadenersatzforderungen, Folgeschäden, gleichgültig welcher Art
            und aus welchem Rechtsgrund, die durch Ihren Zugriff oder die Verwendung der Website,
            insbesondere auch durch Infizierung Ihrer Computerumgebung mit Viren oder durch
            Verwendung der auf der Website angebotenen Informationen, resultieren können.
          </p>
        </p>
      </RichText>
    </Page>
  );
};

export default PrivacyPage;
